import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";


const Register = () => {



  return (
    <Box_home>
      <Container>
        <GroupAcount>
          <Content>
            <TitleAcount>Welcome Back !</TitleAcount>
            <DrepAcount>Keep your face always toward the sunshine - and shadows will fall behind you.</DrepAcount>
          </Content>
          <FormAcount>
            <h3>Create account</h3>
            <p>Made with love by developers for developers.</p>
            <form className="form-ac">
                  <label>Email</label>
                  <input type="text" name="email" pattern="[^ @]*@[^ @]*" placeholder="Name@address.com" required/>
                  <label>Password</label>
                  <input type="password" name="password" placeholder="Password"  required />
                  <label>Referral ID</label>
                  <input type="text" name="ID" placeholder="G-xxxxxxxx"  required/>
                  <GroupCheck>
                    <input type="checkbox" name="" required/> <label>I have read and agree to AppCo</label>
                  </GroupCheck>
                  <BtnPrice type="submit">Send</BtnPrice>
                  <br/>
                  <p>Already registered? <a href="/login">Log in</a></p>
            </form>
      </FormAcount>
        </GroupAcount>
      </Container>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  overflow: hidden;
  min-height:100vh;
  background: url("/images/bn4.jpeg");
  z-index:0;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  
`
const GroupAcount = styled.div`
  padding: 200px 0 100px;
  display: flex;
  width: 100%;

  @media screen and (max-width:1025px){
    flex-wrap:wrap;
    justify-content: center;
    padding: 75px 0 25px;
  }

`

const Content = styled.div`
  max-width:600px;
  width:100%;
`

const TitleAcount = styled.h3`
  font-size:40px;
  color:#fff;
`


const DrepAcount = styled.p`
  font-size:18px;
  color:#d8d8d8;
  max-width:475px;
`


const GroupCheck = styled.p`
  display:flex;
  align-items: center;
  font-size:16px;

  input{
    width:20px!important;
    height:20px!important;
    margin-right:8px;
    marign-bottom:0!important;
  }
  label{
    font-size:16px;
  }
`

const FormAcount = styled.div`
    position: relative;
    overflow: hidden;
    box-shadow: 1px 1rem 3rem rgb(217 184 109 / 82%);
    background: #fff;
    padding: 50px;
    border-radius:12px;
  

  @media screen and (max-width:1025px){
    
  }


  h3{
    font-size:28px;
    margin-bottom:7px;
    color:#000;
  }
  p{
    font-size:18px;
    margin-bottom:7px;
    color:#d5d5d5;
  }

  .form-ac{
    input{
      display: block;
      margin-bottom:15px;
      border: 1px solid #d5d5d5;
      border-radius:15px;
      font-size:16px;
      padding:0 25px;
      width:100%;
      height:35px;
      outline:none;
    }
    h4{
      font-size:16px;
      margin-bottom:5px;
    }
  }

  
 
`



const BtnPrice = styled.button`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
  margin:0 auto;
  max-width:310px;
  width:100%;
  height:50px;
  display:flex;
  justify-content:center;
  align-items: center;
  background: #25b91b;
  border-color: #ffffff;
  color: #fff;
  border-radius: 30px;

  :hover{
    opacity:.8;
  }


  @media (max-width: 770px) {
    margin:0 auto;
  }
`




export default Register
