import React, {  useState } from "react";
import styled from 'styled-components';
import { DataAbout } from "../constants/index";
import TitleHome from "../../../components/TitleHome";
import { Container, Row, Col } from "reactstrap";


const WhyShould = () => {

  const renderItem = (element) => {
    return (
      <ItemAbout>
          <Icon>
              <img src={element.icon} alt="" />
          </Icon>
          <ContentItem>
              <TitleItem>{element.title}</TitleItem>
              <DrepItem>{element.dreption}</DrepItem>
          </ContentItem>
      </ItemAbout>
    );
  };

    return (
        <Box_home>
          <Container>
          <TitleHome title="WHY SHOULD CHOOSE AppCo TO TRADE" drep=""/>
            <GroupAbout>
                  <Thumbnail>
                    <img src="./abt.webp" alt=""/>
                  </Thumbnail>
                  <Content>
                    <h3>AppCo Trading Plarform</h3>
                    <p>AppCo is a trading platform that applies the BINARY OPTION platform, which is currently the most transparent trading platform in the world, participants on the exchange will trade by themselves, not by winning or losing transactions with brokers of the exchange. or Market Makers.</p>
                    <p>We are focused on serving our customers responsibly with the latest innovations in technology. instruments on forex and crypto exchanges, research tools to support transactions on the BO and blockchain platforms.</p>
                    <a href="/about">see more</a>
                  </Content>
              </GroupAbout>
              <GroupAbout>
                  <Content>
                    <h3>AppCo Trading Plarform</h3>
                    <p>AppCo is a trading platform that applies the BINARY OPTION platform, which is currently the most transparent trading platform in the world, participants on the exchange will trade by themselves, not by winning or losing transactions with brokers of the exchange. or Market Makers.</p>
                    <p>We are focused on serving our customers responsibly with the latest innovations in technology. instruments on forex and crypto exchanges, research tools to support transactions on the BO and blockchain platforms.</p>
                    <a href="/about">see more</a>
                  </Content>
                  <Thumbnail>
                    <img src="./abt.webp" alt=""/>
                  </Thumbnail>
              </GroupAbout>
              <GroupAbout>
                  <Thumbnail>
                    <img src="./abt.webp" alt=""/>
                  </Thumbnail>
                  <Content>
                    <h3>AppCo Trading Plarform</h3>
                    <p>AppCo is a trading platform that applies the BINARY OPTION platform, which is currently the most transparent trading platform in the world, participants on the exchange will trade by themselves, not by winning or losing transactions with brokers of the exchange. or Market Makers.</p>
                    <p>We are focused on serving our customers responsibly with the latest innovations in technology. instruments on forex and crypto exchanges, research tools to support transactions on the BO and blockchain platforms.</p>
                    <a href="/about">see more</a>
                  </Content>
              </GroupAbout>
          </Container>
        </Box_home>
    );
  }







const Box_home = styled.section`
  position: relative;
  padding:50px 0;
  width:100%;
  background: ;

    
`



const ItemAbout = styled.div`
    display:flex;
    margin-bottom:15px;
    align-items: center;
`


const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`

const Thumbnail = styled.div`
  max-width:600px;
  width: 100%;
  margin-right:45px;
  
  img{
    width:100%;
  }

  @media (max-width: 1024px) {
    max-width:100%;
    margin-right:0;
  }
`

const Icon = styled.div`
  height: 65px;
  width: 65px;
  color: #9629e6;
  text-align: center;
  box-shadow: 0 4px 15px 0 rgb(52 27 159 / 10%);
  border-radius: 4px;
  transition: all .3s ease-in-out;
  margin-bottom: 0;
  margin-right: 30px;
  background: #fff;
  padding: 15px;
    
  img{
      width:100%;
  }

`

const ContentItem = styled.div`
  position: relative;
`

const Content = styled.div`
  position: relative;
  width: calc(100% - 645px);

  @media (max-width: 1024px) {
    width:100%;
  }

`

const TitleItem = styled.p`
  color:#9629e6;
  font-weight:600;
  margin-bottom:5px

`

const DrepItem = styled.p`
  color:##707070;
`


const GroupAbout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`








export default WhyShould;
