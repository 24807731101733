import React, {  useState } from "react";
import styled from 'styled-components';
import TitleHome from "../../../components/TitleHome";
import { DataDuan } from "../constants/index";
import Image from 'react-image-webp';


const Duan = () => {

    const renderItem = (element) => {
        return (
          <ItemProfit>
             <LinkPro href={element.url}>
              <Icon>
                  {/* <img src={element.icon} alt="" /> */}
                  <Image
                  src={element.icon}
                  srcSet={`${element.icon} 1x, ${element.icon} 2x`}
                  type="webp"
                  alt={element.title}
                />
              </Icon>
              <ContentItem>
                  <TitleItem>{element.title}</TitleItem>
                  <DrepItem>{element.dreption}</DrepItem>
              </ContentItem>
              </LinkPro>
          </ItemProfit>
        );
      };



    return (
        <Box_home>
            <TitleHome title="Dự Án" drep=""/>
            <ListCom>{DataDuan.map((item) => renderItem(item))}</ListCom>
        </Box_home>
    );
  }







  const Box_home = styled.section`
  position: relative;
  padding:50px 8px;
  width:100%;
`

const LinkPro = styled.a`
  position: relative;
  display:flex;
  width:100%;
  padding:25px;
  height:100%;
`

const ItemProfit = styled.div`
  margin-bottom:15px;
  overflow: hidden;
  max-width:calc(100% / 4.2);
  width:100%;
  background:#fff;
  border: 1px solid #eff2f7;
  transition: all .2s ease;
  box-shadow: 0 3px 20px 0 rgb(0 0 0 / 12%);
  margin-right:15px;
  text-align: center;
  cursor: pointer;
  position:relative;
  height:360px;

  

  @media (max-width: 1200px) {
    max-width:49%;

    :nth-child(even){
      margin-right:0;
    }
  }

  @media (max-width: 576px) {
    max-width:100%;
    margin-right:0;
  }
  ::after{
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    background: #0f06064f;
    top: 0;
    left: 0;
    z-index: 0;
  }
`

const Icon = styled.div`
width: 100%;
color: #9629e6;
text-align: center;
box-shadow: 0 4px 15px 0 rgb(52 27 159 / 10%);
border-radius: 4px;
-webkit-transition: all .3s ease-in-out;
transition: all .3s ease-in-out;
margin-bottom: 0;
margin-right: 30px;
background: #fff;
position: absolute;
top: 0;
left: 0;
margin:0 auto;
height:100%;
  
img{
    width:100%;
    height: 100%;
    object-fit: cover;
}

`

const ContentItem = styled.div`
  position: relative;
  z-index:1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
`

const TitleItem = styled.p`
color: #ffc107;
font-weight: bold;
margin-bottom: 5px;

`

const DrepItem = styled.p`
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 342px;
  width:100%;

  @media (max-width: 1600px) {
    max-width:300px;
    
  }
`

const ListCom = styled.div`
display: flex;
width: 100%;
flex-wrap: wrap;
// justify-content: center;
`


export default Duan;
