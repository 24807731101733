import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";


const LienHe = () => {



  return (
    <Box_home>
      <Breadcrumbs title="Liên Hệ" text="" />
      <Container>
      <Content>
          <p>Nếu Bạn muốn có thêm thông tin hoặc thảo luận một dự án tiềm năng, vui lòng liên hệ với chúng tôi theo địa chỉ noithatnakim@gmail.com hoặc điện thoại 0904-097-204.</p>
          <ul>
            <li>Trụ sở chính: 102 đường số 7, KDC City Land Center Hill, P.7, Quận Gò Vấp, Hồ Chí Minh</li>
            <li>Văn Phòng:102 đường số 7, KDC City Land Center Hill, P.7, Quận Gò Vấp, Hồ Chí Minh</li>
            <li>Xưởng sản xuất: Vĩnh phú, Bình Dương</li>
          </ul>
      </Content>
      </Container>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  
  
`


const Content = styled.div`
  width:100%;
  padding:50px 0;
`

const TitleAcount = styled.h3`
  font-size:40px;
  color:#fff;
`


const DrepAcount = styled.p`
  font-size:18px;
  color:#d8d8d8;
  max-width:475px;

`




export default LienHe
