import React, {  useState } from "react";
import styled from 'styled-components';
import { DataAbout } from "../constants/index";
import TitleHome from "../../../components/TitleHome";
import { Container, Row, Col } from "reactstrap";


const EarnUp = () => {

    return (
        <Box_home>
          <Container>
            <GroupEarn>
                <Content>
                    <TitleItem>Earn up to $32 worth of crypto</TitleItem>
                    <DrepItem>Discover how specific cryptocurrencies work — and get a bit of each crypto to try out for yourself.</DrepItem>
                    <BtnPrice  href="/login">Purchase now</BtnPrice>
                </Content>
                <Coin>
                  <LineData>
                    <img src="./btc.png" alt="btc" />
                    <TitleCoin>Bitcoin 💰<span>Earn $5 BTC</span></TitleCoin>
                  </LineData>
                  <LineData>
                    <img src="./clv.svg" alt="eth" />
                    <TitleCoin>Fetch 💰<span>Earn $5 FET</span></TitleCoin>
                  </LineData>
                  <LineData>
                    <img src="./eth.png" alt="btc" />
                    <TitleCoin>Ethereum 💰<span>Earn $5 ETH</span></TitleCoin>
                  </LineData>

                </Coin>
                 
            </GroupEarn>
          </Container>
        </Box_home>
    );
  }







const Box_home = styled.section`
  position: relative;
  padding:100px 0;
  width:100%;
  background: #f4f7fa;


  @media (max-width: 770px) {
    padding: 50px 0;
  }


    
`


const Content = styled.div`
  position: relative;
  width:50%;
  padding: 30px;

  @media (max-width: 770px) {
    width:100%;
    padding: 5px;
    margin-bottom:25px;
  }

`

const Coin = styled.div`
  position: relative;
  width:50%;
  padding-left: 70px;

  @media (max-width: 770px) {
    width:100%;
    padding-left:0;
  }
`


const TitleItem = styled.h3`
  color:#000;
  font-weight:600;
  margin-bottom:5px

`

const TitleCoin = styled.h4`
  display: flex;
  margin-bottom:0;
  justify-content: space-between;
  width: calc(100% - 30px);
  font-size: 22px;

  span{
    color:#ff444f;
    font-size: 16px;
  }
`

const DrepItem = styled.p`
  color:#707070;
`

const LineData = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  margin-bottom:25px;
  border-radius:5px;
  transition: all .2s ease;
  padding: 15px;
  cursor: pointer;

  img{
    max-width:30px;
    width:100%;
    margin-right:8px;
  }

  :hover{
    margin-left:5px;
    background: #fff;
    box-shadow: 1px 2px 2px 2px #e2e2e2;

  }
`


const GroupEarn = styled.div`
  display: flex;
  width:100%;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`

const BtnPrice = styled.a`
  border-radius: 30px;
  color: #6730e3;
  border: 2px solid #6730e3;
  max-width:210px;
  height:50px;
  display:flex;
  justify-content:center;
  align-items: center;
  background: #f95318;
  border-color: #ffffff;
  color: #fff;
  border-radius: 30px;

  :hover{
   opacity:0.8;
   color:#fff;
  }


  @media (max-width: 770px) {
    margin:0 auto;
  }
`






export default EarnUp;
