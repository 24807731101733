import React, { useState } from "react";
import styled from "styled-components";
import { MdKeyboardArrowRight } from "react-icons/md";
import ButtonClose from "../ButtonClose";
import { NavLink } from "react-router-dom";
const ListPlatForm = [
  "Căn Hộ Chung Cư",
  "Custom Product Development sadsadsadsadadasdsdsadsasadsa",
  "Blockchain Development",
  "Security Compliance",
  "Search Engine Optimization",
  
];
function DrawerMobile(props) {
  let { showDrawerMenu, HandleHideDrawer } = props;
  let [showSubmenu, setShowSubmenu] = useState(false);
  function handleShowSubMenu() {
    setShowSubmenu(!showSubmenu);
  }
  function handleCloseDrawer() {
    HandleHideDrawer(false);
    setShowSubmenu(false);
  }
  function renderListPlatForm() {
    return ListPlatForm.map((item, idx) => {
      return (
        <MenuSubItem key={idx}>
          <WrapperItemPlatform>
            <Itemlink href="#">{item}</Itemlink>
          </WrapperItemPlatform>
        </MenuSubItem>
      );
    });
  }
  return (
    <>
      <DrawerOVL showDrawerMenu={showDrawerMenu} onClick={handleCloseDrawer} />
      <WrapperDrawerMobile
        showDrawerMenu={showDrawerMenu}
        className={showDrawerMenu ? "activeDrawerMobile" : ""}
      >
        <WrapperBtn
          onClick={handleCloseDrawer}
          className={showDrawerMenu ? "activeAnimateBtn" : ""}
        >
          <ButtonClose />
        </WrapperBtn>
        <ListMenu className="list__item">
          <MenuItem className="item__lv1">
            <NavLink className="link__item__lv1" to="/">
              Trang Chủ
            </NavLink>
          </MenuItem>
          <MenuItem
            className="item__lv1 item__serviceDrawer"
            onClick={handleShowSubMenu}
          >
            <WrapperService className="wp__service">
              <ItemLink className="link__item__lv1" href="#">
                Dự Án
              </ItemLink>
              <WrapperIconDrop className={showSubmenu ? "activeArrow" : ""}>
                <MdKeyboardArrowRight color="#ffffff" size="1.2rem" />
              </WrapperIconDrop>
            </WrapperService>
            <ListSubMenu
              className={
                showSubmenu ? "list__item active__submenu" : "list__item"
              }
            >
                <NavLink to="/can-ho-chung-cu">Căn Hộ Chung Cư</NavLink>
                <NavLink to="/cong-trinh-thuc-te">Công Trình Thưc Tế</NavLink>
                <NavLink to="/biet-thu">Biệt Thự</NavLink>
                <NavLink to="/">Nhà Hàng - Coffe - Khách Sạn</NavLink>
                <NavLink to="/ngoai-that">Ngoại Thất</NavLink>
                <NavLink to="/nha-pho">Nhà Phố</NavLink>
                <NavLink to="/van-phong-showroom">Văn Phòng Showrooom</NavLink>
            </ListSubMenu>
          </MenuItem>
          <MenuItem className="item__lv1">
            <NavLink className="link__item__lv1" to="/">
              Decor
            </NavLink>
          </MenuItem>
          <MenuItem className="item__lv1">
            <NavLink className="link__item__lv1" to="/phong-thuy">
              Phong Thuỷ
            </NavLink>
          </MenuItem>
          <MenuItem className="item__lv1">
            <NavLink className="link__item__lv1" to="/lien-he">
              Liên Hệ
            </NavLink>
          </MenuItem>
          <MenuItem className="item__lv1">
            <NavLink className="link__item__lv1" to="/tuyen-dung">
              Tuyển Dụng
            </NavLink>
          </MenuItem>
        </ListMenu>
      </WrapperDrawerMobile>
    </>
  );
}
const DrawerOVL = styled.div`
  position: ${(props) => (props.showDrawerMenu ? "fixed" : "")};
  width: ${(props) => (props.showDrawerMenu ? "100%" : "0%")};
  height: ${(props) => (props.showDrawerMenu ? "100vh" : "0px")};
  // top: 0;
  left: 0;
  background: #040404cf;
  z-index: 999;
`;
const WrapperDrawerMobile = styled.div`
  visibility: hidden;
  transform: translateX(100%);
  transition: 0.5s all;
  position: fixed;
  z-index: 9999;
  background: #000;
  right: 0;
  top: 0;
  padding: 60px 20px 20px 20px;
  width: 50%;

  &.activeDrawerMobile {
    transform: translateX(4%);
    transition: 0.5s all;
    visibility: visible;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    height: 100vh;
  }
`

const WrapperService = styled.div`
  &.wp__service {
    position: relative;
  }
`;
const ListMenu = styled.ul`
  list-style: none;
  padding-left: 20px;
`;
const MenuItem = styled.li`
  margin-bottom: 20px;
  &.item__lv1 a.link__item__lv1 {
    font-size: 17px;
    color: #ffffff;
    line-height: 25px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;
const ListSubMenu = styled.ul`
  height: 0px;
  overflow-y: scroll;
  padding: 10px 0px 0px 60px;
  list-style: none;
  transition: 0.5s height cubic-bezier(0.6, 0.045, 0.35, 1.5);
  a {
    color: #ffffff;
    
    @media (max-width: 1024px) {
      display:block;
      line-height: 30px;
    }
  }
  &.active__submenu {
    height: 220px;
    transition: 0.5s height cubic-bezier(0.6, 0.045, 0.35, 1.5);
  }
`;
const MenuSubItem = styled.li`
  margin-bottom: 20px;
  font-size: 14px;
  &.item__serviceDrawer {
    margin-bottom: 0px !important;
  }
`;
const WrapperBtn = styled.div`
  position: absolute;
  top: 15px;
  right: 30px;
  transition: 0.8s all;
  transform: rotate(0deg);
  &.activeAnimateBtn {
    transition: 0.8s all;
    transform: rotate(360deg);
  }
`;
const ItemLink = styled.a``;
const WrapperIconDrop = styled.div`
  top: 0%;
  left: 26%;
  position: absolute;
  transition: 0.5s all;
  transform: rotate(0deg);
  &.activeArrow {
    transform: rotate(90deg);
    transition: 0.5s all;
  }
`;
const WrapperItemPlatform = styled.div`
  max-width: 820px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: #ffffff;
`;
const Itemlink = styled.a`
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export default DrawerMobile;
