import React from "react";
import styled from "styled-components";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { IoLogoFacebook,IoMdMail,IoMdCall  } from "react-icons/io";

function HeaderContentSocial() {
  return (
    <ContentSocial>
      <LinkIconHeader className="h-f facebook-hover-effect" target="_blank" href="https://www.facebook.com/noithatnakim?mibextid=LQQJ4d">
        <FaFacebookF />
      </LinkIconHeader>
      <LinkIconHeader className="h-f twitter-hover-effect" href="tel:0904097204">
        <IoMdCall />
      </LinkIconHeader>
      <LinkIconHeader className="h-f twitter-hover-effect" href="#">
        <FaInstagramSquare />
      </LinkIconHeader>
      <LinkIconHeader className="h-f twitter-hover-effect" href="mailto:noithatnakim@gmail.com">
      <IoMdMail />
      </LinkIconHeader>
    </ContentSocial>
  );
}
const ContentSocial = styled.div`
  margin-left: 0;
  margin-top: 7px;
  width: 250px;
  display: flex;
  justify-content: space-around;
  text-align: center;

  @media screen and (max-width: 1025px) {
    display: none;
  }
  
  @media screen and (max-width: 476px) {
    display: none;
  }

`
const LinkIconHeader = styled.a`
  color: #ffffff;
  width: 30px;
  height: 30px;
  display: block;
  &:hover {
    color: #ffffff;
  }
  &:hover.h-f {
    border-radius: 50%;
  }
  &:hover.facebook-hover-effect {
    background-color: #4267b2;
  }
  &:hover.twitter-hover-effect {
    background-color: #1da1f2;
  }
  &:hover.instagram-hover-effect {
    background: linear-gradient(to bottom, #aa2fb5 0%, #f1762c 93%) !important;
  }
`;
export default HeaderContentSocial;
