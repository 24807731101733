import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";


const TuyenDung = () => {



  return (
    <Box_home>
      <Breadcrumbs title="Tuyển Dụng" text="" />
      <Container>
      <Content>
          <p>Là một trong những nhà thầu nội thất hàng đầu Việt Nam, NaKim chào đón tất cả những người:</p>
          <p>Thích làm việc trong lĩnh vực trang trí nội thất
Hòa đồng và thân thiện cùng đội ngũ thiết kế NaKim
Sáng tạo, đóng góp và phát triển kiến thức chuyên môn với NaKim <br/>
Xin hãy tham khảo ban tuyển dụng của chúng tôi dưới đây và áp dụng ngay!</p>
          <h4>Vị trí ứng tuyển:</h4>
          <ul>
            <li>NV Thiết Kế Nội Thất</li>
            <li>NV Giám Sát</li>
          </ul>
          <p>Hoặc thậm chí bạn chưa tìm được đúng công việc, bạn vẫn có thể thể hiện sự quan tâm của bạn bằng cách gởi một bản lý lịch /CV chung. Chúng tôi sẽ giữ hồ sơ của bạn trong hệ thống và sẽ liên hệ với bạn nếu có công việc phù hợp.</p>
          <h4>Liên hệ tuyển dụng</h4>
          <p><b>Công Ty TNHH Thiết Kế & Trang Trí Nội Thất NaKim</b></p>
          <p>TSC: 102 đường số 7, KDC City Land Center Hill, P.7, Quận Gò Vấp, Hồ Chí Minh .Địa chỉ VP: 102 đường số 7, KDC City Land Center Hill, P.7, Quận Gò Vấp, Hồ Chí Minh</p>
          <p>Hoặc gởi về email: noithatnakim@gmail.com</p>
          <p>Áp dụng ngay bây giờ! Hãy bắt đầu sự nghiệp của bạn và hãy thành công với NaKim!</p>
          <p><b>Tuyển Vị Trí Thiết Kế Nội Thất:</b></p>
						<ul>
							<li>Mức lương: 7-15 triệu (có thưởng)</li>
							<li>Kinh nghiệm: 1 năm trở lên</li>
							<li>Trình độ: Cao đẳng , Đai Hoc</li>
							<li>Ngành nghề: Kinh doanh, Kiến trúc/Nội thất</li>
							<li>Thành thạo 3Dsmax, Auto cad, photoshop...</li>
							<li>Số lượng tuyển dụng: 2</li>
							<li>Giới tính: Nam, Nữ</li>
							<li>Giờ làm việc: Giờ hành chính (T7 làm sáng chiều nghỉ)</li>
							<li>Hình thức làm việc: Nhân viên chính thức</li>
						</ul>
						<p><b>Mô Tả:</b></p>
						<ul>
							<li>Khảo sát mặt bằng, lên ý tưởng, phương án thiết kế.</li>
							<li>Chuyên thiết kế nội thất 3D trong lĩnh vực Căn Hộ chung cư , nhà ở, văn phòng, biệt thự,…</li>
							<li>Cụ thể Công việc sẽ trao đổi trong buổi phỏng vấn.</li>
							<li>Các chế độ bảo hiểm xã hội, bảo hiểm y tế, bảo hiểm thất nghiệp theo quy định của công ty và pháp luật quy định.</li>
						</ul>
						<p><b>Hình Thức Nộp Hồ Sơ:</b></p>
						<ul>
							<li>Gởi hồ sơ vào email: noithatnakim@gmail.com</li>
							<li>Nộp Trực tiếp tại VP Bình Thạnh : 102 đường số 7, KDC City Land Center Hill, P.7, Quận Gò Vấp, Hồ Chí Minh</li>
						</ul>
      </Content>
      </Container>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  
  
`


const Content = styled.div`
  width:100%;
  padding:50px 0;
`

const TitleAcount = styled.h3`
  font-size:40px;
  color:#fff;
`


const DrepAcount = styled.p`
  font-size:18px;
  color:#d8d8d8;
  max-width:475px;

`




export default TuyenDung
