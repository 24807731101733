import React, { useEffect, useState, Suspense, lazy } from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useWindowScroll } from "react-use";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./view/Home";
import Login from "./view/Login";
import ForgotPassword from './view/Login/components/ForgotPassword'
import Register from './view/Login/components/Register'
import About from "./view/About";
import TuyenDung from "./view/TuyenDung";
import Congtrinhthucte from "./view/Congtrinhthucte";
import LienHe from "./view/LienHe";
import Canhobotanicapremier from "./view/Canhochungcu/PageChild/Canhobotanicapremier";
import Canhochungcu from "./view/Canhochungcu";
import PhongThuy from "./view/PhongThuy";
import Canho3phongngu from "./view/Canhochungcu/PageChild/Canho3phongngu";
import Canhogoldenmansion from "./view/Canhochungcu/PageChild/Canhogoldenmansion";
import Canhoceladoncity76m2 from "./view/Canhochungcu/PageChild/Canhoceladoncity76m2";
import Canhodaimonlutus from "./view/Canhochungcu/PageChild/Canhodaimonlutus";
import Canho80m2quan2 from "./view/Canhochungcu/PageChild/Canho80m2quan2";
import Canhonho54m2bontanicahongha from "./view/Canhochungcu/PageChild/Canhonho54m2bontanicahongha";
import Canhorichstart2 from "./view/Canhochungcu/PageChild/Canhorichstart2";
import Canhogovap25 from "./view/Canhochungcu/PageChild/Canhogovap25";
import Canhoocharparview from "./view/Canhochungcu/PageChild/Canhoocharparview";
import Canhorichstarttanphu from "./view/Canhochungcu/PageChild/Canhorichstarttanphu";
import Canhoceladoncity from "./view/Canhochungcu/PageChild/Canhoceladoncity";
import Canholexingtonquann2 from "./view/Canhochungcu/PageChild/Canholexingtonquann2";
import Canhorichstart96m2 from "./view/Canhochungcu/PageChild/Canhorichstart96m2";
import Canhodangcap5sao from "./view/Canhochungcu/PageChild/Canhodangcap5sao";
import Canhosaigonsouthresiden2 from "./view/Canhochungcu/PageChild/Canhosaigonsouthresiden2";
import Canhosaigonsouthresiden1 from "./view/Canhochungcu/PageChild/Canhosaigonsouthresiden1";
import Canhochungcuhado from "./view/Canhochungcu/PageChild/Canhochungcuhado";
import Bietthubienhoa from "./view/Bietthu/PageChild/Bietthubienhoa";
import Bietthu from "./view/Bietthu";
import Bietthukontum from "./view/Bietthu/PageChild/Bietthukontum";
import Congtrinhthuctethegolviewq4 from "./view/Congtrinhthucte/PageChild/Congtrinhthuctethegolviewq4";
import Congtrinhthuctenguyenthuonghien from "./view/Congtrinhthucte/PageChild/Congtrinhthuctenguyenthuonghien";
import Congtrinhthuctenhaphoquan2 from "./view/Congtrinhthucte/PageChild/Congtrinhthuctenhaphoquan2";
import Vanphongshowroom from "./view/Vanphongshowroom/Vanphongshowroom";
import Vanphongquan2 from "./view/Vanphongshowroom/PageChild/Vanphongquan2";
import Nhapho from "./view/Nhapho/Nhapho";
import Nhaphodep from "./view/Nhapho/PageChild/Nhaphodep";
import Nhaphodongnai from "./view/Nhapho/PageChild/Nhaphodongnai";
import Nhaphoquan7 from "./view/Nhapho/PageChild/Nhaphoquan7";
import Nhaphoquangngai from "./view/Nhapho/PageChild/Nhaphoquangngai";
import Nhaphogaclung from "./view/Nhapho/PageChild/Nhaphogaclung";
import Nhaphocontainer from "./view/Nhapho/PageChild/Nhaphocontainer";
import Nhaphochivanquangngai from "./view/Nhapho/PageChild/Nhaphochivanquangngai";
import Vanphongruumt from "./view/Vanphongshowroom/PageChild/Vanphongruumt";
import Vanphongruutm from "./view/Vanphongshowroom/PageChild/Vanphongruutm";
import Ngoaithat from "./view/Ngoaithat";
import Ngoaithatvilakontum from "./view/Ngoaithat/PageChild/Ngoaithatvilakontum";
import Nhapho300m2bato from "./view/Nhapho/PageChild/Nhapho300m2bato";











const App = () => {
  let [menuBg, setMenuBg] = useState(false);
  const { y: pageOffset } = useWindowScroll();
  useEffect(() => {
    if (pageOffset > 70) {
      setMenuBg(true);
    } else {
      setMenuBg(false);
    }
  }, [pageOffset]);
  return (
    <StyledScreen>
      <Router >
      <Suspense fallback={<h2>🌀 Loading...</h2>}>
        <Header styleBg={menuBg} />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/forgotpassword" exact>
            <ForgotPassword/>
          </Route>
          <Route path="/register" exact>
            <Register/>
          </Route>
          <Route path="/about" exact>
            <About/>
          </Route>
          <Route path="/tuyen-dung" exact>
            <TuyenDung/>
          </Route>
          <Route path="/cong-trinh-thuc-te" exact>
            <Congtrinhthucte/>
          </Route>
          <Route path="/can-ho-chung-cu" exact>
            <Canhochungcu/>
          </Route>
          <Route path="/biet-thu" exact>
            <Bietthu/>
          </Route>
          <Route path="/nha-pho" exact>
            <Nhapho/>
          </Route>
          <Route path="/ngoai-that" exact>
            <Ngoaithat/>
          </Route>
          <Route path="/van-phong-showroom" exact>
            <Vanphongshowroom/>
          </Route>
          <Route path="/lien-he" exact>
            <LienHe/>
          </Route>
          <Route path="/phong-thuy" exact>
            <PhongThuy/>
          </Route>
          <Route path="/can-ho-bontanica" exact>
            <Canhobotanicapremier/>
          </Route>
          <Route path="/can-ho-3-phong-ngu" exact>
            <Canho3phongngu/>
          </Route>
          <Route path="/can-ho-golden-mantion" exact>
            <Canhogoldenmansion/>
          </Route>
          <Route path="/can-ho-celadon-city-76m2" exact>
            <Canhoceladoncity76m2/>
          </Route>
          <Route path="/can-ho-daimon-lutus" exact>
            <Canhodaimonlutus/>
          </Route>
          <Route path="/can-ho-80m2-quan-2" exact>
            <Canho80m2quan2/>
          </Route>
          <Route path="/can-ho-nho-54m2-bontanica-hong-ha" exact>
            <Canhonho54m2bontanicahongha/>
          </Route>
          <Route path="/can-ho-richstart-2" exact>
            <Canhorichstart2/>
          </Route>
          <Route path="/can-ho-go-vap-25" exact>
            <Canhogovap25/>
          </Route>
          <Route path="/can-ho-orchar-parview" exact>
            <Canhoocharparview/>
          </Route>
          <Route path="/can-ho-richstart-tan-phu" exact>
            <Canhorichstarttanphu/>
          </Route>
          <Route path="/can-ho-celadon-city" exact>
            <Canhoceladoncity/>
          </Route>
          <Route path="/can-ho-lexington-quan-2" exact>
            <Canholexingtonquann2/>
          </Route>
          <Route path="/can-ho-richstart-96m2" exact>
            <Canhorichstart96m2/>
          </Route>
          <Route path="/can-ho-dang-cap-5-sao" exact>
            <Canhodangcap5sao/>
          </Route>
          <Route path="/can-ho-sai-gon-south-residen-2" exact>
            <Canhosaigonsouthresiden2/>
          </Route>
          <Route path="/can-ho-sai-gon-south-residen" exact>
            <Canhosaigonsouthresiden1/>
          </Route>
          <Route path="/can-ho-chung-cu-ha-do" exact>
            <Canhochungcuhado/>
          </Route>
          <Route path="/biet-thu-bien-hoa" exact>
            <Bietthubienhoa/>
          </Route>
          <Route path="/biet-thu-kontum" exact>
            <Bietthukontum/>
          </Route>
          <Route path="/cong-trinh-thuc-te-the-gol-view-quan-4" exact>
            <Congtrinhthuctethegolviewq4/>
          </Route>
          <Route path="/chung-cu-nguyen-thuong-hien" exact>
            <Congtrinhthuctenguyenthuonghien/>
          </Route>
          <Route path="/cong-trinh-thuc-te-nha-pho-quan-2" exact>
            <Congtrinhthuctenhaphoquan2/>
          </Route>
          <Route path="/van-phong-quan-2" exact>
            <Vanphongquan2/>
          </Route>
          <Route path="/van-phong-ruou-mt" exact>
            <Vanphongruumt/>
          </Route>
          <Route path="/van-phong-ruou-tm" exact>
            <Vanphongruutm/>
          </Route>
          <Route path="/nha-pho-dep" exact>
            <Nhaphodep/>
          </Route>
          <Route path="/nha-pho-dong-nai" exact>
            <Nhaphodongnai/>
          </Route>
          <Route path="/nha-pho-duong-20" exact>
            <Nhaphoquan7/>
          </Route>
          <Route path="/nha-pho-quang-ngai" exact>
            <Nhaphoquangngai/>
          </Route>
          <Route path="/nha-pho-gac-lung" exact>
            <Nhaphogaclung/>
          </Route>
          <Route path="/nha-pho-container" exact>
            <Nhaphocontainer/>
          </Route>
          <Route path="/nha-pho-chi-van-quang-ngai" exact>
            <Nhaphochivanquangngai/>
          </Route>
          <Route path="/ngoai-that-vila-kontum-83" exact>
            <Ngoaithatvilakontum/>
          </Route>
          <Route path="/nha-pho-300m2-bato" exact>
            <Nhapho300m2bato/>
          </Route>
          
          
         
         
        </Switch>
        </Suspense>
      </Router>
      <Footer />
    </StyledScreen>
  );
};
const StyledScreen = styled.div`
  position: relative;
  overflow: hidden;
`;

export default App;
