export const Datacanho = [
  {
    stt:"1",
    icon: "./uploads/canhochungcu/bonta-1.jpg",
  },
  {
    stt:"2",
    icon: "./uploads/canhochungcu/bonta-2.jpg",
  },
  {
    stt:"3",
    icon: "./uploads/canhochungcu/bonta-3.jpg",
  },
  {
    stt:"4",
    icon: "./uploads/canhochungcu/bonta-4.jpg",
 },
 {
  stt:"5",
  icon: "./uploads/canhochungcu/bonta-5.jpg",
},
{
  stt:"6",
  icon: "./uploads/canhochungcu/bonta-6.jpg",
},
{
  stt:"7",
  icon: "./uploads/canhochungcu/bonta-7.jpg",
},
{
  stt:"8",
  icon: "./uploads/canhochungcu/bonta-8.jpg",
},
{
  stt:"9",
  icon: "./uploads/canhochungcu/bonta-9.jpg",
},
{
  stt:"10",
  icon: "./uploads/canhochungcu/bonta-10.jpg",
},
{
  stt:"11",
  icon: "./uploads/canhochungcu/bonta-11.jpg",
},
{
  stt:"12",
  icon: "./uploads/canhochungcu/bonta-12.jpg",
},
{
  stt:"13",
  icon: "./uploads/canhochungcu/bonta-13.jpg",
},
{
  stt:"14",
  icon: "./uploads/canhochungcu/bonta-14.jpg",
},
{
  stt:"15",
  icon: "./uploads/canhochungcu/bonta-15.jpg",
},

 
];




export const Listcanhochungcu = [
  {
    stt:"1",
    icon: "./uploads/canhochungcu/3phongngu-13.jpg",
    title: "Căn Hộ 3 Phòng Ngủ",
    dreption: "",
    url: "/can-ho-3-phong-ngu",
  },
  {
    stt:"2",
    icon: "./uploads/canhochungcu/bonta-5.jpg",
    title: "Căn Hộ Botanica",
    dreption: "",
    url: "/can-ho-bontanica",
  },
  {
    stt:"3",
    icon: "./uploads/canhochungcu/maintion-8.jpg",
    title: "Căn Hộ Golden Mansion",
    dreption: "",
    url: "/can-ho-golden-mantion",
  },
  {
    stt:"4",
    icon: "./uploads/canhochungcu/celadon76-1.jpg",
    title: "Căn Hộ Celadon City 76m2",
    dreption: "",
    url: "/can-ho-celadon-city-76m2",
 },
 {
    stt:"5",
    icon: "./uploads/canhochungcu/lutus-1.jpg",
    title: "Căn Hộ Daimon Lutus",
    dreption: "",
    url: "/can-ho-daimon-lutus",
},
{
    stt:"6",
    icon: "./uploads/canhochungcu/80m2q2-1.jpg",
    title: "Căn Hộ 80m2 Quận 2",
    dreption: "",
    url: "/can-ho-80m2-quan-2",
},
{
    stt:"7",
    icon: "./uploads/canhochungcu/hongha-13.jpg",
    title: "Căn Hộ Nhỏ 54m2 Bontanica Hồng Hà",
    dreption: "",
    url: "/can-ho-nho-54m2-bontanica-hong-ha",
},
{
    stt:"8",
    icon: "./uploads/canhochungcu/richta-2.jpg",
    title: "Căn Hộ Richstart 2",
    dreption: "",
    url: "/can-ho-richstart-2",
},
{
    stt:"9",
    icon: "./uploads/canhochungcu/govap7.jpg",
    title: "Căn Hộ Gò Vấp 25",
    dreption: "",
    url: "/can-ho-go-vap-25",
},
{
    stt:"10",
    icon: "./uploads/canhochungcu/barview13.jpg",
    title: "Căn Hộ Ochar Parview",
    dreption: "",
    url: "/can-ho-orchar-parview",
},
{
    stt:"11",
    icon: "./uploads/canhochungcu/richtanphu1.jpg",
    title: "Căn Hộ Richstart Tân Phú",
    dreption: "",
    url: "/can-ho-richstart-tan-phu",
},
{
    stt:"12",
    icon: "./uploads/canhochungcu/celadon-5.jpg",
    title: "Căn Hộ Celadon City",
    dreption: "",
    url: "/can-ho-celadon-city",
},
{
    stt:"13",
    icon: "./uploads/canhochungcu/lexin1.jpg",
    title: "Căn Hộ Lexington Quận 2",
    dreption: "",
    url: "/can-ho-lexington-quan-2",
},
{
    stt:"14",
    icon: "./uploads/canhochungcu/bonta-5.jpg",
    title: "Căn Hộ Botanica Premier",
    dreption: "",
    url: "/can-ho-bontanica",
},
{
    stt:"15",
    icon: "./uploads/canhochungcu/LG1.jpg",
    title: "Căn Hộ Richstart 96m2",
    dreption: "",
    url: "/can-ho-richstart-96m2",
},
{
    stt:"16",
    icon: "./uploads/canhochungcu/ch4.jpg",
    title: "Căn Hộ Đẳng cấp 5 sao",
    dreption: "",
    url: "/can-ho-dang-cap-5-sao",
},
{
  stt:"17",
  icon: "./uploads/canhochungcu/saigonthou-1.jpg",
  title: "Căn Hộ Sài Gòn South Residen 2",
  dreption: "",
  url: "/can-ho-sai-gon-south-residen-2",
},
{
  stt:"18",
  icon: "./uploads/canhochungcu/residen-1.jpg",
  title: "Căn Hộ Sài Gòn South Residen",
  dreption: "",
  url: "/can-ho-sai-gon-south-residen",
},
{
  stt:"18",
  icon: "./uploads/canhochungcu/hado-4.jpg",
  title: "Căn Hộ Chung Cư Hà Đô",
  dreption: "",
  url: "/can-ho-chung-cu-ha-do",
},
 
];


