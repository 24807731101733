import React from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import SliderContentText from "./SliderContentText";

function SliderItem(props) {
  let { item } = props;
  return (
    <SliderImgItem image={item.imgSlide}>
      <SliderText className="slider__text">
        <Container style={{ height: "100%" }}>
          <Row style={{ height: "100%" }}>
            <Col md="12" style={{ height: "100%" }}>
              <SliderContentText item={item} />
            </Col>
          </Row>
        </Container>
      </SliderText>
    </SliderImgItem>
  );
}
const SliderImgItem = styled.div`
  background-image: ${(props) =>
    props.image
      ? `url("../../../images/${props.image}")`
      : `url("../../../images/l2.jpg")`};
  height: 100vh !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;

  @media screen and (max-width: 476px) {
    background-image: url("../../../images/banner-mb.png")
  }

`;
const SliderText = styled.div`
  height: 100% !important;
`;
export default SliderItem;
