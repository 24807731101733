export const Listcanhochungcu = [
  {
    stt:"1",
    icon: "./uploads/nhapho/batopho-1.jpg",
    title: "NHÀ PHỐ 300m2 BA TƠ QUẢNG NGÃI",
    dreption: "",
    url: "/nha-pho-300m2-bato",
  },
  {
    stt:"1",
    icon: "./uploads/nhapho/chivanquangngai-1.jpg",
    title: "Nhà Phố Chị Vân TP Quảng Ngãi",
    dreption: "",
    url: "/nha-pho-chi-van-quang-ngai",
  },
  {
    stt:"1",
    icon: "./uploads/nhapho/container-1.jpg",
    title: "Nhà Container",
    dreption: "",
    url: "/nha-pho-container",
  },
  {
    stt:"2",
    icon: "./uploads/nhapho/nhaphodep-5.jpg",
    title: "Nhà Phố Đẹp",
    dreption: "",
    url: "/nha-pho-dep",
  },
  {
    stt:"3",
    icon: "./uploads/nhapho/dongnai-1.jpg",
    title: "Nhà Phố Đồng Nai",
    dreption: "",
    url: "/nha-pho-dong-nai",
  },
  {
    stt:"3",
    icon: "./uploads/nhapho/duong20-9.jpg",
    title: "Nhà Phố Quận 7",
    dreption: "",
    url: "/nha-pho-duong-20",
  },
  {
    stt:"4",
    icon: "./uploads/nhapho/quangngai-10.jpg",
    title: "Nhà Phố Quảng Ngãi",
    dreption: "",
    url: "/nha-pho-quang-ngai",
  },
  {
    stt:"5",
    icon: "./uploads/nhapho/gaclung-1.jpg",
    title: "Nhà Phố Gác Lửng",
    dreption: "",
    url: "/nha-pho-gac-lung",
  },
  
 
];


