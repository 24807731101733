import React, { useState, useEffect, } from "react";
import styled from "styled-components";
import MenuDropdown from "../../../../MenuDropdown";
import { IoMdArrowDropdown,  } from "react-icons/io";
import { Link } from "react-router-dom";
import MenuTechnology from "../../../../MenuTechnology";
import MenuPrograming from "../../../../MenuPrograming";
import Language from "../../../../Languge";
import HeaderContentSocial from "../HeaderContentSocial";




function HeaderContentNav({ showMenuRes }) {

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div className="header__content-nav">
      <NavList className="list-unstyled">
        <MenuItem className="item__parent">
          <Link
            className="link__menuHeader"
            activeClassName="active__style"
            exact
            to="/"
          >
            Trang Chủ
          </Link>
        </MenuItem>
        <MenuSub>
            <Link className="link__menuHeader" activeClassName="active__style" to="">Dự Án <IoMdArrowDropdown className="dropIcon" /></Link>
            <SubList className="childsub">
                <Link to="/can-ho-chung-cu">Căn Hộ Chung Cư</Link>
                <Link to="/cong-trinh-thuc-te">Công Trình Thưc Tế</Link>
                <Link to="/biet-thu">Biệt Thự</Link>
                <Link to="/">Nhà Hàng - Coffe - Khách Sạn</Link>
                <Link to="/ngoai-that">Ngoại Thất</Link>
                <Link to="/nha-pho">Nhà Phố</Link>
                <Link to="/van-phong-showroom">Văn Phòng Showrooom</Link>
            </SubList>
      </MenuSub>
       
        <MenuItem className="item__parent">
          <Link className="link__menuHeader" activeClassName="active__style" to="/cong-trinh-thuc-te">
            Decor
          </Link>
        </MenuItem>
        <MenuItem className="item__parent">
          <Link className="link__menuHeader" activeClassName="active__style" exact to="/phong-thuy">
            Phong Thuỷ
          </Link>
        </MenuItem>
        <MenuItem className="item__parent">
          <Link className="link__menuHeader" activeClassName="active__style" exact to="/lien-he">
            Liên Hệ
          </Link>
        </MenuItem>
        <MenuItem className="item__parent">
          <Link className="link__menuHeader" activeClassName="active__style" exact to="/tuyen-dung">
            Tuyển Dụng
          </Link>
        </MenuItem>
        {/* <MenuItem className="item__parent">
          <NavLink className="link__menuHeader btn-regis" to="/register">
            Phong Thuỷ
          </NavLink>
        </MenuItem> */}
        {/* <MenuItem className="item__parent">
          <NavLink className="link__menuHeader btn-login" to="/login">
            Phong Thuỷ
          </NavLink>
        </MenuItem>
       */}
      </NavList>
      <HeaderContentSocial/>
    </div>
  );
}
const NavList = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 812px) {
    display: none;
  }
`


const MenuItem = styled.li`
  margin: 0px 30px;
  position:relative;


  &.item__parent:hover .box__wp {
    display: block;
  }
  .active__style {
    font-weight: bold;
    border-bottom: solid 1px #fff;
  }

  .btn-regis{
    padding:5px 15px;
    border-radius:25px;
    background:#f95318;
  }

  .btn-login{
    border:solid 1px #fff;
    padding:5px 15px;
    border-radius:25px;
  }

  .link__menuHeader {
    cursor: pointer;
    text-decoration: none !important;
    color: #ffffff !important;
    display: flex;
    align-items: center;
    line-height:50px;


    &:hover {
    opacity:.5;
  }
  .link__child{
    color:#000;
  }




  @media screen and (max-width: 1025px) {
    margin: 0px 20px;
  }

  @media screen and (max-width: 1025px) {
    font-size: 14px;
    &.service__item .dropIcon {
      top: 0px;
      left: 0px;
    }
  }

  }
`

const Menuchild = styled.div`
    position:absolute;
    display:none;
    top: 5px;
    left:0;
    max-width:300px;
    width:100%;
    padding:10px;
    background: #fff;
`;


const MenuSub = styled.div`
    position: relative;
    display: inline-block;

    :hover{
      .childsub{
        display:block;
      }
    }

    a{
      color: #fff!important;
    }
`;


const SubList = styled.div`
  display: none;
  position: absolute;
  background-color: #00000078;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  min-width:260px;

  a{
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    :hover{
      opacity:.5;
    }
  }

`;


const BoxWrapper = styled.div`
  padding: 30px 20px;
  right: -633%;
  position: absolute;
  width: 1350px;
  top: 60%;
  min-height: 300px;
  z-index: 9999;
  margin-top: 9px;
  display: none;
  border-radius: 10px;
  background: rgb(1 2 3 / 68%);
  @media screen and (max-width: 476px) {
    &.wrapperDrop__OVL {
      display: none !important;
    }
  }
`;


export default HeaderContentNav;
