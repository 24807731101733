import React, { useState } from "react";
import styled from "styled-components";

const TitleHome = ({ title,drep}) => {
  return (
    <div>
        <ContentTitle className="title">{title}</ContentTitle>
        <Dreption className="drep">{drep}</Dreption>
    </div>

  ) 
 
  ;
};

const ContentTitle = styled.h3`
    margin-bottom: 15px;
    font-size: 32px;
    text-align:center;
    font-weight: bold;
    color:#000;
    width: 100%;

    @media(max-width:770px){
      font-size:24px;
    }

    img{
      max-width:30px;
      width: 100%;
      margin-top:-7px;
    }

`

const Dreption = styled.p`
    font-size: 16px;
    text-align:center;
    color:#000;
    width: 100%;
    max-width:700px;
    margin:0 auto;
    margin-bottom: 25px;

    @media(max-width:770px){
      font-size:24px;
    }
   
`

export default TitleHome;
