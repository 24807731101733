import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import SliderItem from "../Slider/Component/SliderItem/index";
const listContentSlider = [
  {
    numSlide: 1,
    imgSlide: "banner-home2.webp",
    textPosition: "flex-start",
    btnPosition: "left",
    contentTopH3: "",
    contentTopH1: "Nội Thất Nakim",
    contentTopH31: "Thiết kế Thi Công Trọn Gói Mọi Công Trình ",
    contentTopH32:
      "",
    contentP: "",
  },
  {
    numSlide: 2,
    imgSlide: "banner-home3.webp",
    textPosition: "center",
    btnPosition: "center",
    contentTopH3: "",
    contentTopH1: "Thiết Kế Không Gian Sống",
    contentTopH31: "",
    contentTopH32: "",
    contentP:
      "Sáng tạo không gian sống của bạn với nội thất chất lượng cao.",
  },
  {
    numSlide: 3,
    imgSlide: "banner-3.webp",
    textPosition: "flex-end",
    btnPosition: "right",
    contentTopH3: "",
    contentTopH1: "Nakim Decor",
    contentTopH31:
      "Nội thất sáng tạo và độc đáo có thể giúp bạn tạo ra một không gian sống đầy cảm hứng",
    contentTopH32: "",
    contentP: "",
  },
  
 
];
function SliderTop() {
  let [animateText, setAnimateText] = useState(true);
  function handleClick() {
    setAnimateText(!animateText);
  }
  let settings = {
    dots: true,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div
        onClick={handleClick}
        style={{
          background: "transparent",
          height: "100%",
        }}
      >
        <ListSlick>{dots}</ListSlick>
      </div>
    ),
    customPaging: (i) => (
      <DotCustom>
        <Bar className="bar__item"></Bar>
      </DotCustom>
    ),
  };
  function renderListSlideBanner() {
    return listContentSlider.map((item, idx) => {
      return (
        <div>
          <SliderItem item={item} key={idx} />
        </div>
      );
    });
  }
  return (
    <WrapperSlick>
      <Slider {...settings}>{renderListSlideBanner()}</Slider>
    </WrapperSlick>
  );
}
const DotCustom = styled.div`
  height: 100%;
  width: 100%;
`;
const WrapperSlick = styled.div`
  .slick-dots {
    position: initial;
  }
  .slick-dots li {
    margin-bottom: 20px;
    width: 13px;
    height: 13px;
    position: relative;
    border: 3px solid #ffffff;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    box-sizing: content-box;
    &:last-child .bar__item {
      display: none;
    }
  }
`;
const Bar = styled.div`
  width: 3px;
  height: 21px;
  background: #ffffff;
  position: relative;
  top: 15px;
  left: 5px;
`;
const ListSlick = styled.ul`
  margin: 0px;
  display: flex;
  flex-direction: column;
  top: 35%;
  left: 5%;
  position: absolute;
  z-index: 9;
  transform: scale(1) !important;
  li.slick-active {
    transform: scale(1.3) !important;
    margin-top: 2px;
    background: #d2960d;
    transition: all 0.5s ease-in-out;
    .bar__item {
      height: 14px;
    }
  }
  @media screen and (max-width: 812px) {
    display: none;
  }
  @media screen and (max-width: 476px) {
    display: none;
  }
`;
export default React.memo(SliderTop);
