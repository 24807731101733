export const Listcanhochungcu = [
  {
    stt:"1",
    icon: "./uploads/bietthu/bienhoa-4.jpg",
    title: "Công Trình Thực Tế Bontanica 45m2",
    dreption: "",
    url: "/biet-thu-bien-hoa",
  },
  {
    stt:"2",
    icon: "./uploads/bietthu/kontum-8.jpg",
    title: "Goden Mansion",
    dreption: "",
    url: "/biet-thu-kontum",
  },
  {
    stt:"3",
    icon: "./uploads/congtrinhthucte/nhapho-4.jpg",
    title: "Công Trình Thực Tế Nhà Phố Quận 2",
    dreption: "",
    url: "/cong-trinh-thuc-te-nha-pho-quan-2",
  },
  {
    stt:"4",
    icon: "./uploads/congtrinhthucte/thuonghien-6.jpg",
    title: "Công Trình Thực Tế Chung Cư Nguyễn Thượng Hiền, Bình Thạnh",
    dreption: "",
    url: "/chung-cu-nguyen-thuong-hien",
  },
  {
    stt:"5",
    icon: "./uploads/congtrinhthucte/golview-2.jpg",
    title: "Công Trình Thực Tế The Gol View Q4 A.HAI",
    dreption: "",
    url: "/cong-trinh-thuc-te-the-gol-view-quan-4",
  },
  
 
];


