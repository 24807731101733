import React, { useEffect, useState, Suspense } from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-tabs/styles.css";

import SliderTop from "../../components/Slider";
import ScrollTop from "../../components/ScrollTop";
import { useWindowScroll } from "react-use";
import OurClient from "../../components/OurClient/OurClient";
import Steps from "./components/Steps"
import Referral from "./components/Referral";
import EarnUp from "./components/EarnUp";
import Excellents from "./components/Excellents";
import WhyShould from "./components/WhyShould";
import Duan from "./components/Duan";



const Home = () => {
  const { y: pageOffset } = useWindowScroll();
  const [visible, setVisibility] = useState(false);
  useEffect(() => {
    if (pageOffset > 400) {
      setVisibility(true);
    } else {
      setVisibility(false);
    }
  }, [pageOffset]);
  return (
    <Box_home>
      <Suspense fallback={<Loading />}>
        <SliderTop />
        <Steps/>
        <Duan/>
        <Excellents/>
        <OurClient/>
      </Suspense>
      {pageOffset > 100 ? <ScrollTop /> : ""}
    </Box_home>
  );
};

function Loading() {
  return <h2>🌀 Loading...</h2>;
}

const Box_home = styled.section`
  position: relative;
  overflow: hidden;
`;
const Title_home = styled.h2`
  color: ${(props) => props.color};
  font-size: 40px;
  margin-bottom: 24px;
  font-weight: bold;
  line-height: 1.1;
`;

export default Home
