export const DataSteps = [
  {
    stt:"1",
    icon: "./images/optimize/container-1.webp",
    title: "Nhà Container",
    dreption: "Nhà phố mang phong cách hiện đại, độc đáo và sang trọng",
    url: "/nha-pho-container",
  },
  {
    stt:"2",
    icon: "./images/optimize/duong20-9.webp",
    title: "Nhà Phố Quận 7",
    dreption: "Phong cách hiện đại, sử dụng tông màu vàng tạo cảm giác ấm áp.",
    url: "/nha-pho-duong-20",
  },
  {
    stt:"3",
    icon: "./images/optimize/residen-1.webp",
    title: "Căn Hộ Sài Gòn South Residen",
    dreption: "Căn Hộ Tại Quận 7, diện tích 95m2 – 3 phòng ngủ, mang phong cách thiết kế hiện đại.",
    url: "/can-ho-sai-gon-south-residen",
  },
  {
    stt:"4",
    icon: "./images/optimize/saigonthou-1.webp",
    title: "Căn Hộ Sài Gòn South Residen 2",
    dreption: "Căn Hộ cao cấp, diện tích 95m2 – 3 phòng ngủ, thiết kế sáng tạo trong từng đường nét.",
    url: "/can-ho-sai-gon-south-residen-2",
 },
 {
  stt:"5",
  icon: "./images/optimize/bienhoa-4.webp",
  title: "Biệt Thự Biên Hòa",
  dreption: "Biệt Thự 1 Trệt 2 lầu, mang phong Cách Cổ Điển vô cùng sang trọng và đẳng cấp.",
  url: "/biet-thu-bien-hoa",
},
{
  stt:"6",
  icon: "./images/optimize/ch4.webp",
  title: "Căn Hộ Đẳng cấp 5 sao",
  dreption: "Căn Hộ cao cấp happy valey Q7, mang phong cách tân Cổ Điển, diện tích 110m2, 3 phòng ngủ.",
  url: "/can-ho-dang-cap-5-sao",
},
{
  stt:"7",
  icon: "./images/optimize/dongnai-1.webp",
  title: "Nhà Phố Đồng Nai",
  dreption: "Thiết Kế Tầng Áp mái thư giãn, mát mẻ, hòa cùng mảng cây sân vườn.",
  url: "/nha-pho-dong-nai",
},
{
  stt:"8",
  icon: "./images/optimize/richta-2.webp",
  title: "Căn Hộ Richstart 2",
  dreption: "Căn Hộ Quận Tân Phú, Diện tích 80m2, 2 phòng ngủ",
  url: "/can-ho-richstart-2",
},
{
  stt:"9",
  icon: "./images/optimize/LG1.webp",
  title: "Căn Hộ Richstart 96m2",
  dreption: "Căn hộ quận Tân Phú, diện tích 80m2, 3 phòng ngủ",
  url: "/can-ho-richstart-96m2",
},
{
  stt:"10",
  icon: "./images/optimize/barview13.webp",
  title: "Căn Hộ Ochar Parview",
  dreption: "Căn hộ quận Phú Nhuận, diện tích 98m2",
  url: "/can-ho-orchar-parview",
},
{
  stt:"11",
  icon: "./images/optimize/phongruutm-3.webp",
  title: "Phòng Rượu TM",
  dreption: "Phòng rượu sang trọng phong cách hiện đại",
  url: "/van-phong-ruou-tm",
},
{
  stt:"12",
  icon: "./images/optimize/maintion-8.webp",
  title: "Căn Hộ Golden Mansion",
  dreption: "Căn hộ 100m2 mang phong cách bán cổ điển, sử dụng kính trang trí toát lên vẻ sang trọng ...",
  url: "/can-ho-golden-mantion",
},
 
];


export const DataDuan = [
  {
    stt:"1",
    icon: "./images/bg-home.jpg",
    title: "DỰ ÁN DIAMOND LOTUS RIVERSIDE",
    dreption: "Với công viên cây xanh trải dài 500 m dọc bờ sông xanh mát, hữu tình. Diamond Lotus Riverside sở hữu một không gian xanh tuyệt hảo hiếm có và quý giá giữa chốn đô thị phồn hoa",
    url: "/",
  },
  {
    stt:"2",
    icon: "./images/one-verandah.jpg",
    title: "DỰ ÁN CĂN HỘ ONE VERANDAH",
    dreption: "One Verandah là dự án cao cấp của chủ đầu tư Mapletree Singapore, dự án tại mặt tiền sông Sài Gòn, trong khu trung tâm hành chính Quận 2. Đây được coi là dự án đẹp nhất Thạnh Mỹ Lợi, Quận 2",
    url: "/tuyen-dung",
  },
  {
    stt:"3",
    icon: "./images/optimize/Celadon-emerald.webp",
    title: "Dự Án Celadon Emerald",
    dreption: "Hội tụ những giá trị vàng tạo nên một đẳng cấp sống hoàn mỹ. Emerald Precinct được chăm chút từ cảnh quan, tiện ích đến từng chi tiết nhỏ của nội thất bên trong",
    url: "/",
  },
  {
    stt:"4",
    icon: "./images/duan1.jpg",
    title: "DỰ ÁN CĂN HỘ CHUNG CƯ ORCHARD PARKVIEW QUẬN PHÚ NHUẬN",
    dreption: "Liền kề với 3 công viên lớn nhất tại TP.HCM như công viên Gia Định – Hoàng Văn Thụ – mảng xanh Quân khu 7",
    url: "/",
 },
 {
  stt:"5",
  icon: "./images/duan2.jpg",
  title: "DỰ ÁN CĂN HỘ SCENIC VALLEY QUẬN 7",
  dreption: "Khu căn hộ Scenic Valley là dự án khu căn hộ tọa lạc ngay giao lộ sông Thầy tiêu và đường Nguyễn Văn Linh",
  url: "/",
},
{
  stt:"6",
  icon: "./images/optimize/duan3.webp",
  title: "Căn Hộ Đẳng cấp 5 sao",
  dreption: "Căn Hộ cao cấp happy valey Q7, mang phong cách tân Cổ Điển, diện tích 110m2, 3 phòng ngủ.",
  url: "/",
},
{
  stt:"6",
  icon: "./images/optimize/picity.webp",
  title: "Picity High Park Quận 12",
  dreption: "",
  url: "/",
},
{
  stt:"6",
  icon: "./images/st.png",
  title: "ST Moritz Phạm Văn Đồng",
  dreption: "",
  url: "/",
},

 
];


