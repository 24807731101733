import React, {  useState } from "react";
import styled from 'styled-components';
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Container, Row, Col } from "reactstrap";


const Canhodaimonlutus = () => {

    const Canho3phong = [
    {
      stt:"1",
      icon: "./uploads/canhochungcu/lutus-1.jpg",
    },
    {
      stt:"2",
      icon: "./uploads/canhochungcu/lutus-2.jpg",
    },
    {
      stt:"3",
      icon: "./uploads/canhochungcu/lutus-3.jpg",
    },
    {
      stt:"4",
      icon: "./uploads/canhochungcu/lutus-4.jpg",
   },
   {
    stt:"5",
    icon: "./uploads/canhochungcu/lutus-5.jpg",
  },
  {
    stt:"6",
    icon: "./uploads/canhochungcu/lutus-6.jpg",
  },
  {
    stt:"7",
    icon: "./uploads/canhochungcu/lutus-7.jpg",
  },
  
  
   
  ];
  

    const renderItem = (element) => {
        return (
          <ItemProfit>
              <Icon>
                    <img src={element.icon} alt="" />
                </Icon>    
          </ItemProfit>
        );
      };



    return (
        <Box_home>
            <Breadcrumbs title="Căn Hộ Daimon Lutus" text="" />
            <Container>
              <Content>
                <ListCom>{Canho3phong.map((item) => renderItem(item))}</ListCom>
              </Content>
          </Container>
           
        </Box_home>
    );
  }







  const Box_home = styled.section`
    position: relative;
    width:100%;
`

const Content = styled.div`
  width:100%;
  padding:50px 0;
`

const ItemProfit = styled.div`
    margin-bottom:15px;
    overflow: hidden;
    max-width:100%;
    width:100%;
    transition: all .2s ease;
    text-align: center;
    cursor: pointer;
    padding:25px;
    position:relative;

    @media (max-width: 1024px) {
      max-width:100%;
    }
`

const Icon = styled.div`
  max-width: 1024px;
  width:100%;
  margin: 0 auto;
    
  img{
      width:100%;
  }

`

const ContentItem = styled.div`
  position: relative;
`

const TitleItem = styled.p`
  color: #ffc107;
  font-weight: bold;
  margin-bottom: 5px;

`

const DrepItem = styled.p`
  color:#fff;
`

const ListCom = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
`




export default Canhodaimonlutus;
