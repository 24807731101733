import React from "react";
import styled from "styled-components";

function HeaderContentLeft(props) {

  let { stylelogo } = props;
  return (
    <ContentLeft>
      <LinkLogo href="/" stylelogo={stylelogo} >
        <ImgLogo src="./logo.jpg" alt="noithatnakim" />
      </LinkLogo>
    </ContentLeft>
  );
}
const ContentLeft = styled.div`
    max-width:80px;
    width:100%;
`
const LinkLogo = styled.a`
  max-width: 70px;
  display: block;
  overflow: hidden;
`


const ImgLogo = styled.img`
  width: 100%;
  display: block;
`
export default HeaderContentLeft;
