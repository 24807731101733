import React, {  useState } from "react";
import styled from 'styled-components';
import { DataAbout } from "../constants/index";
import TitleHome from "../../../components/TitleHome";
import { Container, Row, Col } from "reactstrap";


const Excellents = () => {
  
    return (
        <Box_home>
          <TitleHome title="" drep="" />
          <TitleDecor>
          <svg viewBox="0 0 1860 200">
    <symbol id="s-text">
      <text textAnchor="middle" x="50%" y="80%">
        Nakim Decor
      </text>
    </symbol>
    <g className="g-ants">
      <use xlinkHref="#s-text" className="text-copy" />
      <use xlinkHref="#s-text" className="text-copy" />
      <use xlinkHref="#s-text" className="text-copy" />
      <use xlinkHref="#s-text" className="text-copy" />
      <use xlinkHref="#s-text" className="text-copy" />
    </g>
  </svg>
            </TitleDecor>
          <DrepDecor>Nội thất không chỉ là trang trí, nó là nơi bạn sống và hít thở mỗi ngày !</DrepDecor>
          <BtnDecor href="tel:0904097204">Liên hệ với chúng tôi</BtnDecor>
        </Box_home>
    );
  }







const Box_home = styled.section`
  position: relative;
  padding:50px 0;
  width:100%;
  background: url("./bred.webp");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index:0;
    
  ::before{
    position :absolute;
    top:0;
    z-index:-1;
    content:'';
    width:100%;
    height:100%;
    // background-image: linear-gradient(to right,rgb(36 36 37 / 84%),rgb(8 8 10 / 81%),rgb(14 13 16 / 82%));
    background: #18141445;
  }
`



const TitleDecor = styled.h3`
  text-transform: uppercase;
  text-align: center;
  font-size: 50px;
 
  svg {
    display: block;
    font: 80px 'Montserrat';
    margin: 0 auto;
}
  .text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 5px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 7.5s infinite linear;
}

.text-copy:nth-child(1){
	stroke: #fff;
	animation-delay: -2;
}

.text-copy:nth-child(2){
	stroke: #000;
	animation-delay: -3s;
}

.text-copy:nth-child(3){
	stroke: #000;
	animation-delay: -4s;
}

.text-copy:nth-child(4){
	stroke: #000;
	animation-delay: -5s;
}

.text-copy:nth-child(5){
	stroke: #FDB731;
	animation-delay: -6s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
}
`


const DrepDecor = styled.p`
  color: #fff;
  text-align:center;
  font-size:22px;  
`

const BtnDecor = styled.a`
  width: 200px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px orange;
  border-radius: 15px;
  background: orange;
  margin: 0 auto;
  color:#fff;
`

const Thumbnail = styled.div`
  max-width:400px;
  width: 100%;
  
  img{
    width:100%;
  }

  @media (max-width: 770px) {
    max-width:100%;
    margin-right:0;
  }
`



const ContentItem = styled.div`
  position: relative;
`

const Content = styled.div`
  position: relative;
  width: calc(100% - 645px);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 25px;

  @media (max-width: 770px) {
    width:100%;
  }

`

const TitleItem = styled.p`
  color:#9629e6;
  font-weight:600;
  margin-bottom:5px

`

const DrepItem = styled.p`
  color:##707070;
`


const GroupAbout = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 770px) {
    flex-wrap: wrap;
  }
`








export default Excellents;
