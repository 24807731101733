import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";


const TuyenDung = () => {



  return (
    <Box_home>
      <Breadcrumbs title="Phong Thuỷ" text="" />
      <Container>
      <Content>
          <p><img src="./1.jpg" alt="" /></p>
          <p>Theo quan điểm của khoa học phong thủy, màu sắc trang phục quần áo, túi xách, ví da, giày dép, trang sức có vai trò to lớn trong việc cân bằng, hỗ trợ và điều hòa yếu tố âm dương – ngũ hành của bản mệnh từng người. Do đó, nắm bắt cách thức sử dụng màu sắc trang phục sao cho phù hợp với quan điểm của quy luật phong thủy là việc bạn nên cân nhắc để quan tâm mỗi ngày.</p>
          <p><b>Quy luật âm dương – ngũ hành</b></p>
          <p>Âm dương – Ngũ hành chính là nhận thức của người xưa về bản chất và quy luật vận động của thế giới. Ngũ hành: Kim, Mộc, Thủy, Hỏa, Thổ. Mỗi hành vừa thể hiện về tính chất, sự vận động biến đổi, vừa thể hiện vị trí trong không gian. Bất cứ một sự vật, hiện tượng nào đó trong tự nhiên hay trong xã hội đều có thể quy về một hành nhất định và cũng đều chứa đựng yếu tố âm dương.</p>
          <p>Ví dụ: Về người, căn cứ vào tuổi mà người ta xác định một người nào đó ứng với hành nào và gọi là mạng. Chẳng hạn, người tuổi Tân Mão ứng với hành Mộc (mạng Mộc). Về phương, phương Bắc ứng với hành Thủy, phương Nam ứng với hành Hỏa, phương Đông ứng với hành Mộc, Phương Tây ứng với hành Kim, trung ương (trung tâm) ứng với hành Thổ. Về màu, màu Đen ứng với hành Thủy, màu Đỏ ứng với hành Hỏa, màu Xanh ứng với hành Mộc, màu Trắng ứng với hành Kim, màu Vàng ứng với hành Thổ…</p>
          <p>Giữa các hành luôn có sự tương hỗ, hoặc kìm hãm lẫn nhau tạo thành mối quan hệ tương sinh, tương khắc.
Quan hệ tương sinh là hành này làm cơ sở cho hành kia hình thành, phát triển như Mộc sinh Hỏa, Hỏa sinh Thổ, Thổ sinh Kim, Kim sinh Thủy, Thủy sinh Mộc (cây cháy sinh lửa; lửa đốt mọi vật thành tro, thành đất; kim loại hình thành trong đất; kim loại nung nóng chảy thành dạng lỏng; nước nuôi cây).
Quan hệ tương khắc là hành này hạn chế, gây trở ngại cho hành kia. Đó là Thủy khắc Hỏa, Hỏa khắc Kim, Kim khắc Mộc, Mộc khắc Thổ, Thổ khắc Thủy (nước dập tắt lửa; lửa làm chảy kim loại; kim loại cắt được cây; cây hút chất màu của đất; đất ngăn nước).
Nếu bạn quan tâm đến thuật phong thủy, nên lưu ý khi chọn màu sắc trang phục, phụ kiện, túi, ví da.</p>
          <p><b>Mạng Kim</b></p>
          <p>– Màu tương sinh: Hãy chọn cho mình những bộ cánh hoặc phụ kiện có màu vàng rực rỡ hoặc màu trắng tinh khiết. Vì Thổ (màu vàng) sinh Kim và chủ nhân mệnh Kim nên màu trắng là màu sở hữu của bản mệnh.</p>
          <p>– Màu tương khắc: Nếu bạn thuộc mạng Kim, thì tốt nhất là nên tránh những màu như màu hồng, màu đỏ, vì những màu này ứng với hành Hỏa, mà Hỏa thì khắc Kim.</p>
          <p>– Gồm các tuổi: Nhâm Thân – 1932, Ất Mùi – 1955, Giáp Tý – 1984, Quý Dậu – 1933, Nhâm Dần – 1962, Ất Sửu – 1985, Canh Thìn – 1940, Quý Mão – 1963, Tân Tỵ – 1941, Canh Tuất – 1970, Giáp Ngọ – 1954, Tân Hợi – 1971 .Màu sắc những phụ kiện này rất hợp với mạng Kim</p>
          <p><b>Mạng Mộc</b></p>
          <p>– Màu tương sinh: Thực tế, có khá nhiều người thuộc mạng Mộc yêu thích màu xanh. Và đó cũng chính là màu bản mệnh của họ và những bộ trang phục hoặc phụ kiện màu xanh sẽ giúp người mạng Mộc cảm thấy thoải mái, tươi vui hơn. Ngoài ra, người mạng Mộc cũng rất hợp với màu đen hoặc xanh đen – tượng trưng cho hành Thủy – vì Thủy sinh Mộc.</p>
          <p>– Màu tương khắc: Người mạng Mộc nên kiêng màu trắng vì màu trắng tượng trưng cho hành Kim mà Kim thì khắc Mộc. Nếu lỡ yêu thích màu trắng, Mộc hãy phối thêm với các phụ kiện có màu sắc khác để giảm bớt sự tương khắc.</p>
          <p>– Gồm các tuổi: Nhâm Ngọ – 1942, Kỷ Hợi – 1959, Mậu Thìn – 1988, Quý Mùi – 1943, Nhâm Tý – 1972, Kỷ Tỵ – 1989, Canh Dần – 1950, Quý Sửu – 1973, Tân Mão – 1951, Canh Thân – 1980, Mậu Tuất – 1958, Tân Dậu – 1981. Mệnh Mộc nên chọn màu xanh hoặc xanh đen hơn là sắc trắng</p>
          <p><b>Mạng Thủy</b></p>
          <p>– Màu tương sinh: Màu đen tượng trưng cho hành Thủy và chắc bạn cũng dễ dàng đoán ra người mạng Thủy hợp nhất với đen. Ngoài ra, những bộ trang phục, phụ kiện màu trắng cũng sẽ rất hợp với bản mệnh của bạn đấy vì Kim sẽ sinh Thủy.</p>
          <p>– Màu tương khắc: Theo quan hệ tương khắc thì Thổ là hành khắc hành Thủy, vì thế, bạn hãy tránh dùng trang phục hoặc phụ kiện có màu vàng và vàng đất.</p>
          <p>– Gồm các tuổi: Bính Tý – 1936, Quý Tỵ – 1953, Nhâm Tuất – 1982, Đinh Sửu – 1937, Bính Ngọ – 1966, Quý Hợi – 1983, Giáp thân – 1944, Đinh Mùi – 1967, Ất Dậu – 1945, Giáp Dần – 1974, Nhân Thìn – 1952, Ất Mão – 1975. Phụ kiện màu đen hoặc trắng sẽ làm cân đối sắc màu trên cơ thể người mệnh Thủy</p>
          <p><b>Mạng Hỏa</b></p>
          <p>– Màu tương sinh: Một chiếc váy có màu xanh nhẹ nhàng sẽ khiến ngày đầu xuân của bạn thêm tươi mới. Vì bản mệnh của bạn rất hợp với màu xanh (Mộc sinh Hỏa). Đặc biệt, nếu có làn da trắng, tươi tắn bạn có thể chọn màu đỏ hoặc hồng (màu bản mệnh của Hỏa) để luôn nổi bật giữa mùa xuân mới.</p>
          <p>– Màu tương khắc: Ngày đầu xuân, chắc hẳn bạn cũng chẳng muốn “đen cả năm” nên ắt sẽ tránh trang phục đen. Tuy nhiên, điều này nên được lưu ý thường xuyên vì mạng Hỏa cũng tương khắc với màu đen tượng trưng cho hành Thủy (Thủy khắc Hỏa).</p>
          <p>– Gồm các tuổi: Giáp Tuất – 1934, Đinh Dậu – 1957, Bính Dần – 1986, Ất Hợi – 1935, Giáp Thìn – 1964, Đinh Mão – 1987, Mậu Tý – 1948, Ất Tỵ – 1965, Kỷ Sửu – 1949, Mậu Ngọ – 1978, Bính Thân – 1956, Kỷ Mùi – 1979</p>
          <p><b>Mạng Thổ</b></p>
          <p>– Màu tương sinh: Người mạng Thổ có khá nhiều sự lựa chọn màu cho trang phục trong ngày đầu năm mới. Bởi họ rất hợp với màu đỏ, màu hồng (Hỏa sinh Thổ), còn màu vàng và vàng đất lại chính là màu bản mệnh của Thổ nên càng tốt hơn.</p>
          <p>– Màu tương khắc: Người mạng Thổ nên tránh dùng màu xanh trong trang phục vì Mộc khắc Thổ.</p>
          <p>– Gồm có các tuổi: Mậu Dần – 1938, Tân Sửu – 1961, Canh Ngọ – 1990, Kỷ Mão – 1939, Mậu Thân – 1968, Tân Mùi – 1991, Bính Tuất – 1946, Kỷ Dậu – 1969, Đinh Hợi – 1947, Bính Thìn – 1976, Canh Tý – 1960, Đinh Tỵ – 1977. Màu đỏ và hồng rất hợp với mệnh Thổ</p>

      </Content>
      </Container>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  
  
`


const Content = styled.div`
  width:100%;
  padding:50px 0;

  img{
    text-align: center;
  }
`

const TitleAcount = styled.h3`
  font-size:40px;
  color:#fff;
`


const DrepAcount = styled.p`
  font-size:18px;
  color:#d8d8d8;
  max-width:475px;

`




export default TuyenDung
