import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import HeaderContentLeft from "./HeaderContentLeft";
import HeaderContentRight from "./HeaderContentRight";
function Header(props) {
  let { styleBg } = props;
  // console.log(styleBg);
  return (
    <HeaderScroll>
      <HeaderTop styleBg={styleBg} className="HeaderTop">
        <Container fluid={true}>
          <HeaderContent>
            <HeaderContentLeft styleBg={styleBg}/>
            <HeaderContentRight styleBg={styleBg} />
          </HeaderContent>
        </Container>
      </HeaderTop>
    </HeaderScroll>
  );
}

const HeaderScroll = styled.div`

`;

const HeaderTop = styled.header`
  position: ${(props) => (props.styleBg ? "fixed" : "absolute")};
  background: ${(props) =>
    props.styleBg ? "#12182394" : "transparent"};
  padding: 9px 0px;
  width: 100%;
  z-index: 9999 !important;
  transition: ease all 0.5s;

  
  @media screen and (max-width: 769px) {
    
  }
`;
const HeaderContent = styled.div`
  margin: 0px 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: 769px) {
    margin: 0px;
  }
  @media screen and (max-width: 476px) {
    margin: 0px;
  }

  @media (max-width: 1200px) {
    margin: 0 5%;
  }
`;
export default Header;
