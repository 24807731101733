import React, { useEffect } from "react";
import styled from "styled-components";
import Button from "../../../../Button";
import useWow from "../../../../Wow";
function SliderContentText(props) {
  let {
    btnPosition,
    textPosition,
    contentTopH1,
    contentP,
    contentTopH3,
    numSlide,
    contentTopH31,
    contentTopH32,
  } = props.item;
  const { initWow } = useWow();
  useEffect(() => {
    initWow();
  }, []);
  function renderTextBySlide() {
    if (numSlide === 1) {
      return (
        <>
          <TextSubtitle className="wow fadeInUp">{contentTopH3}</TextSubtitle>
          <TextTitle>{contentTopH1}</TextTitle>
          <TextSubtitle className="wow fadeInUp">{contentTopH31}</TextSubtitle>
          <TextSubtitle className="wow fadeInUp">{contentTopH32}</TextSubtitle>
          <WrapperBtn btn={btnPosition} className="wow fadeInUp">
          <a href="tel:0904097204" children="Liên Hệ" />
          </WrapperBtn>
        </>
      );
    } else if (numSlide === 2) {
      return (
        <>
          <TextSubtitle className="wow fadeInUp" data-wow-delay="6s">
            {contentTopH3}
          </TextSubtitle>
          <TextTitle>{contentTopH1}</TextTitle>
          <WrapperTextSlide2 className="wow fadeInUp" data-wow-delay="7s">
            <TextSlide2>{contentP}</TextSlide2>
          </WrapperTextSlide2>
          <WrapperBtn
            btn={btnPosition}
            className="wow fadeInUp"
            data-wow-delay="7s"
          >
            <a href="tel:0904097204" children="Liên Hệ" />
          </WrapperBtn>
        </>
      );
    } else if (numSlide === 3) {
      return (
        <>
          <TextSubtitle className="wow fadeInUp" data-wow-delay="11s">
            {contentTopH3}
          </TextSubtitle>
          <TextTitle>{contentTopH1}</TextTitle>
          <TextSubtitle
            className="text-right wow fadeInUp"
            data-wow-delay="12s"
          >
            {contentTopH31}
          </TextSubtitle>
          <WrapperBtn
            btn={btnPosition}
            className="wow fadeInUp"
            data-wow-delay="13s"
          >
            <a href="tel:0904097204" children="Liên Hệ" />
          </WrapperBtn>
        </>
      );
    } else if (numSlide === 4) {
      return (
        <>
          <TextSubtitle className="wow fadeInUp" data-wow-delay="14s">
            {contentTopH3}
          </TextSubtitle>
          <TextTitle>{contentTopH1}</TextTitle>
          <WrapperTextSlide4>
            <TextSlide4 className="wow fadeInUp" data-wow-delay="15s">
              {contentP}
            </TextSlide4>
          </WrapperTextSlide4>
          <WrapperBtn
            btn={btnPosition}
            className="wow fadeInUp"
            data-wow-delay="15.5s"
          >
            <a href="tel:0904097204" children="Liên Hệ" />
          </WrapperBtn>
        </>
      );
    } else if (numSlide === 5) {
      return (
        <>
          <TextTitle>{contentTopH1}</TextTitle>
          <WrapperTextSldie5>
            <TextSlide5 className="wow fadeInUp" data-wow-delay="21s">
              {contentP}
            </TextSlide5>
          </WrapperTextSldie5>
          <WrapperBtn
            btn={btnPosition}
            className="wow fadeInUp"
            data-wow-delay="22s"
          >
            <a href="tel:0904097204" children="Liên Hệ" />
          </WrapperBtn>
        </>
      );
    }
  }
  return (
    <SliderWrapperText text={textPosition}>
      {renderTextBySlide()}
    </SliderWrapperText>
  );
}
const SliderWrapperText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => (props.text ? props.text : "flex-start")};
  color: #ffffff;
  @media screen and (max-width: 1025px) {
    align-items: center;
    padding: 0px 9%;
  }
  @media screen and (max-width: 812px) {
    padding: 0px;
  }
  @media screen and (max-width: 476px) {
    padding: 0px;
  }
`;
const TextTitle = styled.h1`
  margin: 20px 0px;
  @media screen and (max-width: 1025px) {
    font-size: 30px !important;
  }
  @media screen and (max-width: 476px) {
    text-align: center;
    font-size: 16px;
    width: 100%;
  }
`;
const TextSubtitle = styled.h3`
  font-size: 20px;
  line-height: 24px;
  @media screen and (max-width: 1025px) {
    text-align: center !important;
    font-size: 16px !important;
  }
  @media screen and (max-width: 476px) {
    width: 100%;
    text-align: center !important;
    font-size: 13px !important;
  }
`;
const TextSlide2 = styled.p`
  text-align: center !important;
  font-size: 16px !important;
`;
const TextSlide4 = styled.p`
  font-size: 16px;
  text-align: left;
  @media screen and (max-width: 1025px) {
    text-align: center;
    line-height: 24px !important;
  }
  @media screen and (max-width: 476px) {
    text-align: center;
  }
`;
const TextSlide5 = styled.p`
  fonts-size: 13px;
  text-align: right;
  @media screen and (max-width: 1025px) {
    text-align: center;
    font-size: 16px !important;
    line-height: 24px;
  }
  @media screen and (max-width: 476px) {
    text-align: center;
    font-size: 16px !important;
  }
`;
const WrapperBtn = styled.div`
  margin-top: 50px;
  width: 100%;
  display: block;
  text-align: ${(props) => (props.btn ? props.btn : "center")};
  @media screen and (max-width: 1025px) {
    text-align: center;
  }
`;
const WrapperTextSlide2 = styled.div`
  width: 54%;
  @media screen and (max-width: 1024px) {
    width: 100%; !important;
  }
  @media screen and (max-width: 476px) {
    width: 100%;
  }
`;
const WrapperTextSlide4 = styled.div`
  width: 54%;
    @media screen and (max-width: 1024px) {
    width: 100%; !important;
  }
  @media screen and (max-width: 476px) {
    width: 100% !important;
  }
`;
const WrapperTextSldie5 = styled.div`
  width: 54%;
  @media screen and (max-width: 1024px) {
    width: 100%; !important;
  }
  @media screen and (max-width: 476px) {
    width: 100%;
  }
`;
export default SliderContentText;
