import React from "react";
import styled from "styled-components";
import Text from "./components/Text";
import Nav from "./components/Nav";
import { IoLogoFacebook,IoMdMail,IoMdCall  } from "react-icons/io";

function Footer() {
  return (
    <StyledFooter>
        <div className="">
            <FooterColumn>
                <ItemTop>
                    <h3>Giới Thiệu Về Nakim</h3>
                    <LogoFooter><img src="/logo.jpg" alt=""/> </LogoFooter>
                    <p>Gần 10 năm kinh nghiệm trong ngành kiến trúc và nội thất, Nakim không ngừng học hỏi và sáng tạo. Các dự án của chúng tôi là sự kết hợp giữa nghệ thuật và công năng sử dụng để mang lại một không gian sống đẹp, thư giãn lí tưởng cho mọi gia đình.</p>
                </ItemTop>
                <ItemTop>
                    <h3>Thông Tin Chung</h3>
                    <p><b>CÔNG TY TK VÀ TT NỘI THẤT NAKIM</b></p>
                    <ul>
                      <li>Web: noithatnakim.com</li>
                      <li>Hotline: +(84) 0904097204</li>
                      <li>Email: noithatnakim@gmail.com</li>
                      <li>VP-Trụ sở chính: 102 đường số 7, KDC City Land Center Hill, P.7, Quận Gò Vấp, Hồ Chí Minh</li>
                      <li>Xưởng sản xuất: Vĩnh Phú 20, Thuận An, Bình Dương</li>
                    </ul>
                </ItemTop>
                <ItemTop>
                    <h3>Dịch Vụ Của Chúng Tôi</h3>
                    <p>Thiết Kế Căn Hộ Chung Cư</p>
                    <p>Thiết Kế Nhà Phố</p>
                    <p>Thiết Kế Văn Phòng Showroom</p>
                    <p>Công Trình Thi Công Thực Tế</p>
                </ItemTop>
                <ItemTop>
                    <h3>Kết Nối Với Chúng Tôi</h3>
                    <GroupIcon>
                      <a target="_blank" href="https://www.facebook.com/noithatnakim?mibextid=LQQJ4d"><IoLogoFacebook /></a>
                      <a href="mailto:noithatnakim@gmail.com"><IoMdMail /></a>
                      <a href="tel:0904097204"><IoMdCall /></a>
                    </GroupIcon>
                    
                </ItemTop>
            </FooterColumn>
            <FooterColumn >
                <ContentFooter>
                    <p>Power by Noithatnakim.com @copyright 2020</p>
                </ContentFooter>
            </FooterColumn>
        </div>
    </StyledFooter>

  );
};




const StyledFooter = styled.footer`
  position: relative;
  padding: 20px 0;
  overflow: hidden;
  background-color: #000;
  color:#fff;


  
  @media (max-width: 770px) {
    
  }


  // .line-column{
  //   border-top: 1px solid #fff;
  //   padding-top: 25px;
  // }
`





const LogoFooter = styled.a`
  max-width: 110px;
  display: block;
  overflow: hidden;
  margin-right:20px;
`

const GroupIcon = styled.div`
  position:relative;
  display:flex;
  flex-drection:column;

  a{
    display: flex;
    background: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    margin-right:5px;
    transition: all .2s ease;

    :hover{
      margin-top: -5px;
    }
  }
`


const FooterColumn = styled.div`
    display: flex;
    width:100%;
    padding: 0 10%;

   
    @media (max-width: 1200px) {
      flex-wrap: wrap;
      padding: 0 5%;
    }

    @media (max-width: 1024px) {
      padding: 0 2%;
    }
`



const ItemTop = styled.div`
    width:calc(100% / 4);

    @media (max-width: 1024px) {
      width: 100%;
      padding: 0 15px;

      :nth-child(2){
        padding-left:20px;
      }
      :nth-child(3){
        padding-left:20px;
      }
    }

    h3{
      font-size:18px;
      margin-bottom:20px;
    }
    ul{
      margin-left:15px;
    }
    p{
      font-size:14px;
    }
    input{
      outline:none;
      border:none;
      border-radius:0;
    }

    :nth-child(2){
      padding-left:20px;
    }
    :nth-child(3){
      padding-left:20px;
    }
`

const ContentFooter = styled.div`
    width: 100%;

    @media (max-width: 770px) {
      width: 100%;
    }

    p{
      font-size:14px;
      margin-top:20px;
    }
`





export default Footer;
