import React, { useState, useEffect, } from "react";
import styled from 'styled-components'
import { Containerfluid, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Breadcrumbs";
import ListCongtrinh from "./components/ListCongtrinh";


const Congtrinhthucte = () => {



  return (
    <Box_home>
      <Breadcrumbs title="Công Trình Thực Tế" text="" />
      <div>
          <ListCongtrinh/>
      </div>
  </Box_home>
  )
}

const Box_home = styled.section`
  position: relative;
  
  
`


const Content = styled.div`
  width:100%;
  padding:50px 0;
`

const TitleAcount = styled.h3`
  font-size:40px;
  color:#fff;
`


const DrepAcount = styled.p`
  font-size:18px;
  color:#d8d8d8;
  max-width:475px;

`




export default Congtrinhthucte
