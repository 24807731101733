import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Container, Row, Col } from "reactstrap";
import TitleHome from "../TitleHome"

const OurClient = () => {

  const DataProduct = [
    {
      stt:"1",
      icon: "./uploads/sanpham/decor-1.jpg",
      title: "Nk SF 01",
    },
    {
      stt:"2",
      icon: "./uploads/sanpham/decor-2.jpg",
      title: "Nk SF 02",
    },
    {
      stt:"3",
      icon: "./uploads/sanpham/decor-3.jpg",
      title: "Nk SF 03",
    },
    {
      stt:"4",
      icon: "./uploads/sanpham/decor-4.jpg",
      title: "Nk SF 04",
    },
    {
      stt:"5",
      icon: "./uploads/sanpham/decor-5.jpg",
      title: "Nk SF 05",
    },
    {
      stt:"6",
      icon: "./uploads/sanpham/decor-6.jpg",
      title: "Nk SF 05",
    },
    {
      stt:"7",
      icon: "./uploads/sanpham/decor-7.jpg",
      title: "Nk SF 05",
    },
    {
      stt:"8",
      icon: "./uploads/sanpham/decor-8.jpg",
      title: "Nk SF 05",
    },
    {
      stt:"9",
      icon: "./uploads/sanpham/decor-9.jpg",
      title: "Nk SF 05",
    },
    {
      stt:"10",
      icon: "./uploads/sanpham/decor-10.jpg",
      title: "Nk SF 05",
    },
  
  
   
  ];

  const renderItem = (element) => {
    return (
      <ItemSP>
      <ThumSP><a href="">
      
      {/* <img src={element.icon} alt="" /> */}
              <picture>
                <source media="(max-width: 480px)" srcSet={element.icon} />
                <source media="(min-width: 800px)" srcSet={element.icon} />
                <img src={element.icon} alt={element.title} />
              </picture>
      </a></ThumSP>
                <h4 className="title-box-sp">{element.title}</h4>
                <p className="btn-more"><a href="">Xem Thêm</a></p>
      </ItemSP>
    );
  };
  

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 700,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <OurSlick>
      <Container>
      <TitleHome title="Trang Trí Nội Thất" drep=""/>
        <DivOurClient>
          <Slider {...settings}>
            {DataProduct.map((item) => renderItem(item))}
          </Slider>
        </DivOurClient>
      </Container>
    </OurSlick>
  );
};

const DivOurClient = styled.div`
  .slick-slider {
    z-index: 10;
  }
`;

const OurSlick = styled.div`
  position: relative;
  padding: 50px 0;
  overflow: hidden;
  height:600px;

  @media only screen and (max-width: 576px) {
    
  }


  .slick-slide{
    margin-right: 10px!important;
  }
`;
const OurSlickImages = styled.div`
  z-index: 10;
  padding: 20px;
  text-align: center;

  p{
    img{
      max-width:120px;
      width:100%;
    }
  }
`

// Style product
const ItemSP = styled.div`
  margin-right: 10px;
  text-align: center;
  padding-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px #e4dddd;

  .title-box-sp{
    font-size: 18px;
    margin-bottom: 15px;
  }

  .btn-more{
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px orange;
    box-shadow: 1px 2px 6px 2px #f1f1f1;
    width: 100px;
    height: 25px;
    margin: 0 auto;
    border-radius: 20px;

    a{
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: #000000;
      font-size: 16px;
    }
  }

`;

const ThumSP = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
  height:250px;
  overflow:hidden;

  img{
    width: 100%;
    height:100%;
  }
`;


export default OurClient;
