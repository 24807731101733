export const Listcanhochungcu = [
  {
    stt:"1",
    icon: "./uploads/vanphong-showroom/phongruutm-3.jpg",
    title: "Phòng Rượu TM",
    dreption: "",
    url: "/van-phong-ruou-tm",
  },
  {
    stt:"1",
    icon: "./uploads/vanphong-showroom/phongruumt-2.jpg",
    title: "Phòng Rượu MT",
    dreption: "",
    url: "/van-phong-ruou-mt",
  },
  {
    stt:"1",
    icon: "./uploads/canhochungcu/vpq2-1.jpg",
    title: "Văn Phòng Quận 2",
    dreption: "",
    url: "/van-phong-quan-2",
  },
  
  
 
];


