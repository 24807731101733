export const Listcanhochungcu = [
  {
    stt:"1",
    icon: "./uploads/bietthu/bienhoa-4.jpg",
    title: "Biệt Thự Biên Hòa",
    dreption: "",
    url: "/biet-thu-bien-hoa",
  },
  {
    stt:"2",
    icon: "./uploads/bietthu/kontum-8.jpg",
    title: "Biệt Thự Kontum",
    dreption: "",
    url: "/biet-thu-kontum",
  },
  
 
];


