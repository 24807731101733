import WOW from "wowjs";
const useWow = () => {
  const initWow = () => {
    const wow = new WOW.WOW({
      boxClass: "wow", // default
      animateClass: "animated", // default
      offset: 0, // default
      mobile: true, // default
      live: true,
    });
    wow.init();
  };

  return { initWow };
};

export default useWow;
